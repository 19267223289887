import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Callout from '../lib/Callout';

const IntegrationGuide = () => {
  // return (
  //   <div className="d-none d-md-block">
  //     <Alert variant={'info'}>
  //       Do you work for a Bank or Cryptocurrency Exchange? Its easy to integrate XRPSCAN in your app's workflow. Read our <Alert.Link href="https://docs.xrpscan.com/help/integration-guide" target="_blank">Integration guide &rarr;</Alert.Link>
  //     </Alert>
  //   </div>
  // );

  return (
    <div className="d-sm-block">
      <Callout title="NEW" variant="primary">
        <span className='ml-1'>
          Try our new advanced search, especially designed for <strong>AML</strong>, <strong>compliance</strong>, and <strong>risk management</strong> teams.
          <Alert.Link href="https://console.xrpscan.com/" target="_blank" className='ml-1'>View Console &rarr;</Alert.Link>
        </span>
      </Callout>
    </div>
  );
}

export default IntegrationGuide;