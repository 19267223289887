import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import Search from './Search';
import logo from '../../assets/images/xrpscan-logo.png';
import './Header.css';


const Header = () => {
	return (
		<header className="Header">
			<Container>
			  <Navbar variant="dark" className="justify-content-between">
			    <Navbar.Brand>
			    	<Link to="/">
				      <img
				        alt="XRPSCAN Logo"
				        src={logo}
				        height="30"
				        className="d-inline-block align-middle"
				      />
			      </Link>
			    </Navbar.Brand>
			    <Nav className="justify-content-end" activeKey="/amendments">
						<Nav.Item><Link to="/amms" className="nav-link active">AMMs</Link></Nav.Item>
						<Nav.Item><Link to="/metrics" className="nav-link active">Metrics</Link></Nav.Item>
						<Nav.Item><Link to="/validators" className="nav-link active">Validators</Link></Nav.Item>
						<Nav.Item><Link to="/amendments" className="nav-link active">Amendments</Link></Nav.Item>
						<Nav.Item className="d-none d-md-block">
							<a href="https://console.xrpscan.com" className="nav-link active" target="_blank" rel="noopener noreferrer">Search</a>
						</Nav.Item>
			    </Nav>
			    <Search/>
			  </Navbar>
		  </Container>
	  </header>
	);
}

export default Header;